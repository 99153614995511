import './vendor/jquery.carouFredSel-6.2.1'
import './vendor/jquery.fancybox'

(function (w, d, $) {
  'use strict'

  $(function () {
    let cookie = (() => {
      let set = (name, value, options = {}) => {
        return document.cookie = [
          name + '=' + encodeURIComponent(value),
          options.expires ? '; expires=' + new Date(new Date().getTime() + (options.expires * 24 * 60 * 60 * 1000)).toUTCString() : '',
          options.path ? '; path=' + options.path : ''
        ].join('')
      }

      let map = () => {
        let cookies = document.cookie.split('; ')
        let maps = {}

        for (let cookie of cookies) {
          cookie = cookie.split('=')
          maps[cookie[0]] = decodeURIComponent(cookie[1])
        }

        return maps
      }

      let get = (name) => map()[name]
      let remove = (name, options) => set(name, '', $.extend({}, options, { expires: -1 }))

      return {
        set, map, get, remove
      }
    })()


    $('#menu').on('click', function (e) {
      e.preventDefault();

      $('body').toggleClass('menu--open')
    })


    $('a.lightbox').fancybox({
      'transitionIn': 'none',
      'overlayColor': '#000',
      'transitionOut': 'none',
      'titlePosition': 'over',
      'titleFormat': function (title, currentArray, currentIndex, currentOpts, href) {
        return '<span id="fancybox-title-over">' + (currentIndex + 1) + ' / ' + currentArray.length + (title.length ? ' &nbsp; ' + title : '') + '<span id="fancybox-title-fs">[ <a href="' + currentArray[currentIndex] + '">pełny ekran</a> ]</span><div class="clear"></div></span>'
      }
    })


    let url = w.location.href.substr(w.location.href.lastIndexOf('/') + 1)
    if (w.location.pathname === '/') {
      $('#nav_header > ul > li:first-child').addClass('current')
    } else {
      $('#nav_header > ul > li > a').each(function () {
        if ($(this).attr('href') === url) {
          $(this).parent().addClass('current')
        }
      })
    }


    if ($('#right').length) {
      function setNavi ($i) {
        $('#cblock_1_title').html($i.attr('title'))
      }

      let cblock_1 = $('#cblock_1').carouFredSel({
        height: null,
        width: 400,
        responsive: true,
        items: {
          height: null,
          width: 400,
          visible: 1
        },
        align: 'center',
        pagination: '#cblock_1_pag',
        //auto: false,
        scroll: {
          fx: 'crossfade',
          items: 1,
          // easing: 'easeInOutExpo',
          easing: 'elastic',
          duration: 1500,
          timeoutDuration: 5000,
          pauseOnHover: true,
          onBefore: function (data) {
            $('#cblock_1_title').animate({ opacity: 0, top: -10 }, 300)
          },
          onAfter: function (data) {
            setNavi(data.items.visible)
            $('#cblock_1_title').animate({ opacity: 1, top: 5 }, 300)
          }
        },
        onCreate: function (data) {
          setNavi(data.items)
        }
      }, {
        transition: true,
        onWindowResize: 'debounce'
      })
      cblock_1.on('updateSizes.cfs', function (e) {
        let $parent = $(e.target).parent()
        let height = $parent.width() * .75

        $parent.css('height', height)
      })

      let rmapka = $('#right .mapka')
      let htop = rmapka.offset().top
      let hbot = $(d).height() - rmapka.height() - $('#footer').height()

      $(w).on('scroll', function () {
        let y = $(this).scrollTop()

        if ((y > htop || $(w).scrollTop() > htop) && ($(w).height() > rmapka.height())) {
          $(rmapka).addClass('fixed')
        } else {
          $(rmapka).removeClass('fixed')
        }
        if (y > hbot) {
          $(rmapka).addClass('bottom')
        } else {
          $(rmapka).removeClass('bottom')
        }
      }).trigger('scroll')
    }


    $('#partnerzy_mod img[data-href]').each(function () {
      $(this).wrap('<a href="http://www.' + $(this).attr('data-href') + '" target="_blank"></a>')
    })


    let tabContainers = $('div#dojazd > div')
    tabContainers.hide().filter(':first').show()
    $('div#dojazd ul a').on('click', function (e) {
      e.preventDefault()

      tabContainers.hide()
      tabContainers.filter(this.hash).show()

      $('div#dojazd ul a').removeClass('selected')
      $(this).addClass('selected')
    }).filter(':first').trigger('click')

    if (!cookie.get('cookie-alert')) {
      let $cookie_alert = $('<div>', {
        id: 'cookie_alert',
        class: 'cookie-alert',
        html: `
            <div class="cookie-alert__main">
              <p>Nasza strona wykorzystuje pliki cookies. U\u017cywamy informacji zapisanych za pomoc\u0105 cookies i podobnych technologii m.in. w celach statystycznych oraz by dostosowa\u0107 serwis do potrzeb u\u017cytkownik\u00F3w. W programie s\u0142u\u017c\u0105cym do obs\u0142ugi internetu mo\u017cna zmieni\u0107 ustawienia dotycz\u0105ce cookies. Korzystanie z naszej strony internetowej bez zmiany ustawie\u0144 dotycz\u0105cych cookies oznacza, \u017Ce b\u0119d\u0105 one zapisane w pami\u0119ci urz\u0105dzenia.</p>
              <button id="cookie_alert_close" class="cookie-alert__close btn">Zamknij</button>
            </div>`
      }).appendTo($('body'))

      $('#cookie_alert_close', $cookie_alert).on('click', function (e) {
        e.preventDefault()

        cookie.set('cookie-alert', 1, { path: '/', expires: 365 })
        $cookie_alert.addClass('is-hidden')
      })
    }
  })
}(window, document, jQuery))